.lesson_title {
  color: #414d55 !important;
  font-weight: 600;
  font-size: 2.25rem;
}

.lesson_content {
  width: 100%;
  padding-right: 1rem;
}

.subject_select {
  width: 20rem !important;
}
