.settingsMenuCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5vh;
}

.settingsMenuItem {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.1vw;
    line-height: 1vw;
    color: #9BA5AC;
    margin: 0 2vh;
    cursor: pointer;
    padding: 1vh;
    background: #FFFFFF;
    border: 2px solid rgba(43, 101, 240, 0);
    border-radius: 6px;
}

.settingsMenuItem:hover {
    color: #5189ef;
}

.settingsMenuItem.active {
    padding: 1vh;
    background: #FFFFFF;
    color: #2C66F0;
    border: 2px solid rgba(43, 101, 240, 0.3);
    border-radius: 6px;
}

.settingsPlusButton {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 2vw;
    line-height: 1vw;
    color: #2C66F0;
    margin: 0 2vh;
    cursor: pointer;
    padding: 1vh 3vh;
    background: #FFFFFF;
    border: 2px solid rgba(43, 101, 240, 0.3);
    border-radius: 6px;
}

.settingsDtmActionsIcon {
    color: #616E7B;
    font-size: 1.3vw;
    margin: 0.3vh;
    cursor: pointer;
}

.settingsDtmActionsIcon:hover {
    color: #5189ef;
}

.settingsSubjectEditModalTitle {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.9vw;
    line-height: 2vw;
    color: #414D55;
    text-align: center;
}

.settingsSubjectEditModalCloseIconCont {
    position: absolute;
    top: 5%;
    right: 5%;
    color: #616E7B;
    font-size: 2.3vw;
    cursor: pointer;
}

.nameEditInputLabel {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2vw;
    margin-right: 1vw;
    width: 30%;
    color: #414D55;
}

.settingsEditModalSaveButton {
    border-radius: 5px;
    margin: 1vh auto;
    text-align: center;
}

.FlatEditUploadImgButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10px;
    width: 100px;
    height: 32px;
    font-size: 15px;
    background: #067BE8;
    border: 1px solid #1B92FF;
    box-sizing: border-box;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: all ease-out 0.4s;
}

.FlatEditUploadImgButton:hover {
    background-color: #fff;
    color: #067BE8;
}

.metaTagsCont {
  display: flex;
  margin-top: 10px;
  margin-left: 30px;
}

.metaTag {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  color: #9BA5AC;
  border-radius: 6px;
}

.metaTagActive {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 6px;
  color: #2C66F0;
  border: 2px solid rgba(43, 101, 240, 0.3);
}

.nameEditTypography {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: left;
  width: 58%;
  color: #000000;
}

.chipsCont {
  width: 600px !important;
}

