.usersModal .ant-modal-content {
  border-radius: 10px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.usersModal .ant-form-item {
  margin: 0;
}
.usersModal_title {
  text-align: center;
  font-family: Montserrat, serif;
  font-size: 1.5rem;
  font-weight: 500;
}

.usersModal_item {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}
.usersModal_item > p {
  width: 6.5rem;
  text-align: right;
  align-self: center;
  margin: 0;
  margin-right: 0.75rem;
}

.usersModal_item > div .ant-picker {
  border-radius: 5px;
  width: 100%;
  background: #f7f9fb;
}

.usersModal_item.birthday > div {
  width: 74.5%;
}

.usersModal_item input,
.usersModal_item .ant-select .ant-select-selector {
  width: 22rem;
  border-radius: 5px;
  background: #f7f9fb;
}
.usersModal_item .photoLabel {
  width: 75%;
  margin: 0;
  padding-top: 5px !important;
  font-weight: normal;
  text-align: center;
  color: #fff;
}

.usersModal_button {
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  padding-top: 0;
  width: 8rem;
}
.usersModal .ant-modal-footer {
  display: none;
}

.usersModal .password > div {
  width: 75%;
}

.usersModal .password .ant-input-password {
  background-color: #f7f9fb;
  border-radius: 5px;
}

.usersPlusBtn {
  border-radius: 5px;
  border: 2px solid #1890ff;
  color: #1890ff;
  width: 4vw;
  text-align: center;
}
