.modal {
  &_controls {
    display: flex;
    justify-content: flex-start;
  }
  &_info {
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    font-weight: 600;
  }
}
