body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f1f5f8 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

/*.ant-input-affix-wrapper {*/
/*    width: 20vw;*/
/*}*/

.searchInput {
  position: absolute;
  display: inline-block;
  width: 20vw !important;
  background: #e5ecf2 !important;
  border: none !important;
  height: 5vh;
  top: -6vh;
  font-size: 1.8vw !important;
  border-radius: 0.2vw !important;
}

.searchInput .ant-input {
  font-size: 1.2vw !important;
  padding-left: 4px !important;
  color: #8a8b8d;
  background: #e5ecf2 !important;
}

.searchInput .ant-input-prefix {
  color: #b5bdc3;
}

.sectionTitle {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2vw;
  line-height: 2.5vw;
  transform: translateY(-2vh);
  color: #414d55;
}

.bodyCont {
  background-color: #fff;
  width: 80vw;
  height: 85vh;
  border-top-left-radius: 0.6vw;
  border-top-right-radius: 0.6vw;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.04);
  overflow: scroll;
}

.bodyCont::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: transparent;
  position: absolute;
}
.bodyCont::-webkit-scrollbar-thumb {
  background-color: #5d6881;
  border-radius: 8px;
}
.ant-scrolling-effect {
  width: calc(100% - 3.2px) !important;
}
.ant-scrolling-effect .header {
  width: calc(100% - 3.2px) !important;
}
