
.spin {
  height: 35vh;
  text-align: center;
  display: grid;
  place-content: center;
}
.table_head {
  font-size: 122%;
  width: 74%;
  background-color: #e5ecf2;
  border-radius: 5px;
  height: 5vh;
  text-align: center;
  padding: 0 3.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
}
.list_table {
  display: block !important;
  font-weight: 500;
}
.list_table td {
  width: 8vw;
  text-align: center;
}
.list_table td:first-child {
  width: 5vw;
}
.list_table td:last-child button {
  padding: 0 5px;
  margin: 0 5px;
}
.list_table .fan {
  width: 15vw;
}
.list_table .mavzu_title {
  margin-right: 1vw;
}
.list_table .mavzu {
  text-align: left;
  width: 20vw;
}

/*  =========== table item ============= */
.table_item {
  font-size: 108%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3.25rem;
}
.table_wrapper {
  margin-top: 3rem;
  overflow-y: scroll;
  max-height: 70vh;
  display: block;
  margin-right: 2.5%;
  /* margin-right: .5rem; */
}
/* scrollbar styles */

/* width */
.table_wrapper::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.table_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.table_wrapper::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.table_wrapper::-webkit-scrollbar-thumb:hover {
  background: #717171;
}

.table_item td {
  padding: 1rem 0;
  display: inline-block;
  height: 100%;
}
.table_item td button:last-child:hover {
  color: red;
  border-color: red;
}
