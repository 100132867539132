.news {
  overflow-y: auto !important;
  height: 90vh !important;
  .add_news {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 2vw 2vw 0;
    h2 {
      font-family: Montserrat, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 2.2vw;
      line-height: 1.2vw;
      color: #414d55;
    }
  }
  .add_news_table {
    height: max-content;
    .ant-table-fixed-header {
      border-radius: 10px 10px 0px 0px;
    }
    .ant-table-header {
      border-radius: 10px 10px 0px 0px;
      table {
        tr {
          th {
            background: #e5ecf2;
          }
        }
      }
    }
  }
}

.paginationNews {
  .ant-pagination-options {
    display: none;
  }
}

.news_pagination {
  display: flex;
  padding: 20px 0;
  &_size {
    display: flex;
    align-items: center;
    margin-right: 40px;
    p {
      margin: 0 10px 0 0;
    }
  }
}

.add_all_news {
  margin: 0 25px 0 0;
}

.add_news {
  &_content {
    background: #ffffff;
    border-radius: 10px;
    padding: 30px;
    height: 82vh;
    &_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
      height: 12%;
      &_name {
        width: 72%;
        display: flex;
        flex-direction: column;
        input {
          width: 100%;
        }
      }
      &_lang {
        width: 28%;
        display: flex;
        flex-direction: column;
        > label {
          margin-left: 40px;
        }
        > div {
          margin-left: 40px;
          width: 70%;
          @media only screen and (max-width: 1400px) {
            width: 85%;
          }
        }
      }
    }
    &_text {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: 88%;
      &_left {
        width: 72%;
        height: 100%;
        > div {
          height: 85%;
        }
      }
      &_right {
        width: 28%;
        display: flex;
        flex-direction: column;
        &_top {
          width: 70%;
          display: flex;
          flex-direction: column;
          margin-left: 40px;
          @media only screen and (max-width: 1400px) {
            width: 85%;
          }
        }
        &_bottom {
          margin: 20px 0 0 40px;
          button {
            &:nth-child(1) {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}
