.lessonTableContainer {
  .ant-table-fixed-header {
    border-radius: 10px 10px 0px 0px;
  }
  .ant-table-header {
    border-radius: 10px 10px 0px 0px;
    table {
      tr {
        th {
          background: #e5ecf2;
        }
      }
    }
  }
}

.createSectionModal {
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
      > h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        color: #414d55;
        text-align: center;
        margin-bottom: 32px;
      }
      > form {
        width: 100%;
        > button {
          background: #2b65f0;
          border-radius: 5px;
          height: 35px;
          width: 150px;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
.selectPosition {
  background: #ffffff;
  border: 1px solid #0c4292;
  border-radius: 5px;
  width: 250px;
  .ant-select-selector {
    border-radius: 5px;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #0c4292;
    > svg {
      margin-right: 6px;
    }
  }
}
.selectPositionDropdown {
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    > svg {
      margin-right: 6px;
    }
  }
}

.lesson_table_footer {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  &_right {
    display: flex;
    align-items: center;
    > button {
      margin-left: 17px;
    }
  }
  .ant-pagination-options {
    display: none;
  }
}

.row-dragging {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  > td {
    width: 100%;
    &:nth-child(1),
    &:nth-child(2) {
      width: max-content;
      min-width: max-content;
    }
    &:nth-child(3) {
      min-width: 300px;
      text-align: left;
    }
  }
}

.row-dragging td {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.edit-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
