.feedback-text {
  width: 35%;
}

.table-action-cell .ant-table-row .ant-table-cell:last-child {
  margin: 0 auto;
}
.table-action-cell .ant-table-thead tr th:last-child {
  text-align: center;
}

.table-action-cell .btn {
  border: none;
  border-radius: 50%;
  background: transparent;
  box-shadow: none;
}
.table-action-cell .btn svg {
  font-size: 1.2rem;
}

.wrongQuestionModal .ant-modal-content {
  border-radius: 20px;
}
.wrongQuestionModal .ant-modal-body {
  display: flex;
  flex-direction: column;
}
.wrongQuestionModal h2 {
  font-family: montserrat, serif;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;
}

.wrongQuestionModal .ant-modal-footer {
  display: none;
}

.wrongQuestionModal .save-btn {
  width: 7rem;
  min-height: 2.5rem;
  margin: 0 auto;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  justify-self: center;
}

.wrongQuestionForm {
  display: flex;
  flex-direction: column;
}

.wrongQuestionModal .ant-checkbox-wrapper {
  margin-bottom: 1rem;
}

.wrongQuestionForm div {
  margin: 0;
  width: 100%;
  margin-right: 0.25rem;
}

.wrongQuestionForm .preview {
  margin-bottom: 1rem;
}

.wrongQuestionForm .question {
  margin-bottom: 1rem;
}

.wrongQuestionForm .question > div {
  width: 68%;
}
.wrongQuestionForm .question p {
  margin-right: 1.5rem;
  margin-bottom: 0;
}
