.topBarCont {
  position: fixed;
  height: 7vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  padding: 1.5vh 2.5vw;
  align-items: center;
}

.topBarLogo {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6vw;
  line-height: 2vw;
  color: #7e8a95;
  display: flex;
  align-items: center;
  margin-right: 4vw;
}
.topBarSearch {
  margin-right: auto;
  max-width: 25vw;
  justify-self: start !important;
}

.topBarSearch input {
  font-size: 1rem;
  height: 4vh;
  background-color: #e5ecf2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 2.5vw;
}

/* for making exact same seach panel as in design */
/* .topBarSearch::after{
    position: absolute;
    content: '';
    top: 1vh;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5000;
    display: block;
    width: 10px;
    height: 10px;
    background-color: red;
} */

.topBarSearch .ant-input-group-addon button {
  border-left: none;
  height: 4vh;
  background-color: #e5ecf2;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.topBarText {
  padding-left: 7px;
}

.topBarUserCont {
  display: flex;
  align-items: center;
}

.topBarAvatar {
  width: 2vw;
  border-radius: 50%;
  margin-right: 1vw;
}

.topBarUserName {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9vw;
  line-height: 1vw;
  color: #414d55;
}

.topBarUserRole {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.8vw;
  line-height: 0.8vw;
  padding-top: 0.5vh;
  color: #414d55;
}

.mainSectionCont {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding-top: 7vh;
}

.navBarCont {
  position: fixed;
  z-index: 2;
  padding-left: 2.5vw;
  padding-top: 2vh;
  width: 17vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.contentCont {
  padding-left: 17vw;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.navBarMenuItemsCont {
  display: flex;
  align-items: center;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2vw;
  line-height: 1.2vw;
  color: #9ba5ac;
  margin-top: 1.5vw;
}

.navBarMenuItemsCont2 {
  transition: 0.3s ease;
  svg {
    margin-right: 3.4vh;
    width: 1.7vw;
    height: 1.7vw;
    transition: 0.3s ease !important;
    g {
      path {
        transition: 0.3s ease !important;
      }
    }
  }
  &:hover {
    svg {
      fill: #2c66f0 !important;
      g {
        path {
          fill: #2c66f0 !important;
        }
      }
    }
  }
}

.navBarMenuItemsCont:hover {
  color: #5189ef;
}

.navBarMenuItemsIcon {
  font-size: 1.7vw !important;
  margin-right: 1.4vw !important;
}

.scrollable_content {
  overflow-y: scroll;
  height: 93vh;
  .content_body {
    height: auto !important;
  }
  .questionList {
    height: auto !important;
  }
}

// .add_news_table {
//   .add_all_news {
//     .ant-spin-nested-loading {
//       .ant-spin-container {
//         .ant-table {
//           .ant-table-container {
//             .ant-table-content {
//               table {
//                 box-shadow: -0.15vw 0.22vw 1.1vw rgba(165, 165, 165, 0.1) !important;
//                 border-radius: 0.73vw !important;
//                 thead {
//                   background: #e5ecf2 !important;
//                   // border-radius: 0.73vw 0.73vw 0vw 0vw !important;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
