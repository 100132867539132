/* there is styles for common components  */

/* ======subject form======= */
.subjectForm {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4.25rem;
}

.selectBox:not(:last-child) {
  margin-right: 1rem !important;
}
.selectBox p {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.selectBox .select {
  width: 10rem;
}
/* background for selector */
.selectBox .select .ant-select-selector,
.selectBox input {
  border-radius: 5px !important;
  background: #f7f9fb !important;
}

.subjectForm_check {
  align-self: center;
  margin-top: 5px;
  border-radius: 5px !important;
  padding: 8px;
  padding-top: 4px !important;
}
/* ======subject form======= */

/* ======template downloads======= */

.templateDownloads {
  display: flex;
  flex-direction: column;
  width: 13rem;
  font-size: 12px;
  font-weight: 500;
  margin-top: 2rem;
}

.templateDownloads p span {
  color: #b20000;
}

.templateDownloads button {
  padding: 0;
  margin-bottom: 1rem;
  border-radius: 5px;
  height: 1.5rem;
}

.import-btn {
  flex-grow: 1;
  height: 1.5rem;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 400;
  background-color: #1890ff;
  text-align: center;
  padding: 1px 0;
  color: white;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.import-btn:hover {
  cursor: pointer;
  background-color: #188fffd2;
}
.import-btn.disabled {
  color: #d9d9d9;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
}
.import-btn.disabled:hover {
  cursor: not-allowed;
}

.download-btn {
  width: 100%;
  text-align: center;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  color: #fff;
  background: #1890ff;
  padding: 0.1rem;
  font-weight: 400;
  font-size: 0.875rem;
  transition: background-color 0.3s ease-in-out;
}
.download-btn:hover {
  color: #fff;
  background: #1890ffd2;
}
.download-btn:visited {
  color: #fff;
}

/* ======template downloads======= */

/* ======Question list======= */
.questionList {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 57%;
  scroll-behavior: smooth;
  transition: all 0.3s ease-in-out;
  padding-top: 1rem;
}
.questionList button {
  border-radius: 5px;
}

.questionList .add-btn {
  padding: 5px;
  text-align: center;
  display: block;
  width: 90%;
  height: 2rem;
  margin-left: 2.5rem;
}

.questionList .ant-back-top {
  right: 12vw;
  bottom: 14vh;
}
.questionList .questionList_pagination {
  position: absolute;
  top: 10rem;
}

/* width */
.questionList::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.questionList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.questionList::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.questionList::-webkit-scrollbar-thumb:hover {
  background: #717171;
}
/* ======Question list======= */

/* ======Question add======= */
.questionAdd textarea {
  border-radius: 5px !important;
  background: #f7f9fb !important;
  margin-right: 0.375rem;
  width: 100%;
}

.questionAdd label {
  padding-right: 0.75rem;
}

.questionAdd img[alt='preview'] {
  margin-right: 0.75rem;
}

.questionAdd button {
  width: 1.875rem;
  height: 1.875rem;
  padding: 5px;
  margin-right: 0.75rem;
}

.variantList .ant-radio-group {
  width: 100%;
  font-weight: 700;
  font-size: 0.75rem;
}
.variantList li {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}
.variantList li > p {
  margin-right: 1.25rem;
}
.variantList {
  padding-left: 2 !important;
}
/* .variantList input[type=text]{
  height: 1.75rem;
} */
.variantList li div[role='alert'] {
  font-weight: normal;
}
.question {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}
.question p {
  font-weight: 700;
  font-size: 1.1rem;
  margin-right: 1.125rem;
}
.question .check-btn {
  padding-top: 3px;
}
.preview {
  margin: 0 auto;
  /* margin-right: 2rem!important; */
  margin-bottom: 0.5rem;
  width: 80%;
  padding: 0.5rem;
  text-indent: 1rem;
  border: 1px solid #414d55;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trash-btn {
  padding: 0 6px !important;
}
/* styling for checkbox inside of the question list  */
.previewCheck {
  top: 13rem;
  position: absolute;
}
/* reset for styling of inputs */
.questionList .ant-row.ant-form-item {
  width: 100%;
  margin-right: 0.375rem;
  margin-bottom: 0rem;
}

/* ======Question add======= */

.d-none {
  display: none;
  visibility: hidden;
  opacity: 0;
  z-index: 0;
}
/* btn for switching table and tests on pages dtm, virtual and mavzuli(subjectTest) */
.plus-btn {
  border-radius: 5px;
  border-color: #2c66f0;
  color: #2c66f0;
  align-self: center;
}

.test_head {
  display: flex;
  justify-content: space-between;
  width: 80vw;
}
.test_head h1 {
  margin: 0;
}
.photoLabel {
  padding: 0 3px !important;
  padding-top: 3px !important;
  margin-right: 0.75rem;
  z-index: 100;
  background-color: #1890ff;
  border-radius: 5px;
  height: 32px;
  transition: all 0.3s ease-in-out;
}

.photoLabel:hover {
  cursor: pointer;
  background: #1890ffd2;
}

.photoInput {
  position: absolute;
}
/* popover window delete image button */
.img button {
  position: absolute;
  top: 5px;
  right: 5px;
}

/* questions view */
.questionsView {
  max-height: 70vh;
  overflow-y: scroll;
}
.questionsView::-webkit-scrollbar {
  width: 10px;
}
.questionsView::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.questionsView::-webkit-scrollbar-thumb {
  background: #888;
}
.questionsView::-webkit-scrollbar-thumb:hover {
  background: #717171;
}
.questionsView p.trueAnswer {
  color: green;
  font-weight: 700;
}
.questionsView p {
  margin-left: 1rem;
  margin-bottom: 0.6rem;
}
.questionsView p.que {
  font-weight: 700;
  margin-left: 0;
  margin-bottom: 0.2rem;
  margin-top: 0.6rem;
}
