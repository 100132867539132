.stats {
  &_title {
    color: #414d55 !important;
    font-weight: 600;
    font-size: 2.25rem;
  }
  &_body {
    color: #414d55 !important;
    background: #ffffff;
    padding: 1.5rem 2.25rem 1rem;
    box-shadow: -2px 3px 15px rgba(165, 165, 165, 0.1);
    border-radius: 10px;
    width: 80vw;
    height: 100vh;
    color: #414d55;
    padding-right: 1rem;
  }
  &_content {
    margin-top: 0.75rem;
    height: 100%;
  }
  &_view {
    display: flex;
    justify-content: space-between;
    max-width: 65rem;
    margin: 0 auto;
    height: 90%;
  }

  &_controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &_list {
    flex: 1;
    height: 70%;
    margin-top: 2rem;
    table {
      width: 80%;
      thead {
        font-weight: 700;
      }
      tbody .eyeIcon {
        transition: color 0.15s ease-in;
        font-size: 1.2rem;
        &:hover {
          cursor: pointer;
          color: #2c66f0;
        }
      }
    }
  }
  &_diagram {
    width: 62%;
  }
  &_filter {
    max-width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .ant-select {
      width: 100% !important;
    }
  }
  &_modal {
    width: 60rem !important;
  }
}

////////////////////////////////////////////////////////////////////
////////////////////////////DIAGRAM/////////////////////////////////
////////////////////////////////////////////////////////////////////
.diagram {
  flex-grow: 1;
  &_title {
    text-align: center;
    color: #414d55;
  }
  &_filter {
    span {
      margin-right: 1rem;
      color: #9ba5ac;
    }
  }
  &_pie {
    height: 60%;
  }
}
