.loginTitle {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6vw;
    padding: 20px;
    line-height: 43px;
    color: #414D55;
    text-align: center;
}

.loginInputs {
    border-radius: 1vw;
    padding: 20px;
}

.submitButton {
    width: 100%;
}

.companyName {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 300;
    margin-top: 30vh;
    font-size: 1.1vw;
    line-height: 21px;
    text-align: center;
    color: #9BA5AC;
}
