/* .virtual_title{
  color: #414D55 !important;
  font-weight: 600;
  font-size: 2.25rem;
} */

/* .virtual_body {
  display: flex;
  justify-content: space-between;
  color: #414D55 !important;
  background: #FFFFFF;
  padding: 2.5rem 3rem 1rem 2.25rem;
  box-shadow: -2px 3px 15px rgba(165, 165, 165, 0.1);
  border-radius: 10px;
  width: 80vw;
  height: 100vh;
} */
.virtual_content {
  width: 100%;
  padding-right: 1rem;
}
